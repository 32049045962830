import { useEffect, useMemo } from 'react';
import { useRouteError } from 'react-router';
import { RUMCustomErrorContext } from '@gonfalon/telemetry';

import { trackRouteError } from './trackRouteError';

type UseTrackRouteErrorArgs = RUMCustomErrorContext & {
  disableAutoTrack?: boolean;
};

/**
 * Wrapper around `useRouteError` hook that sends the error to Datadog.
 * @returns Route error object
 */
export function useTrackRouteError({ isCrash, severity, disableAutoTrack }: UseTrackRouteErrorArgs = {}) {
  const error = useRouteError();
  const trackError = useMemo(() => () => trackRouteError(error, { isCrash, severity }), [error, isCrash, severity]);

  useEffect(() => {
    if (!disableAutoTrack) {
      trackError();
    }
  }, [trackError]);

  return {
    error,
    trackError,
  };
}
